import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'JoinArray',
  pure: false
})
export class JoinArrayPipe implements PipeTransform {
  transform(input: Array<any>, sep = ', '): string {
    return input.map(value => value.itemName).join(sep);
  }
}