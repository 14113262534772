import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreditCardValidators } from 'angular-cc-library';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { APP_CONSTANT, AppConst } from 'src/app/core/_constants/app.constant';
import { MainService } from 'src/app/core/_services/main.service';
import { ValidationService } from 'src/app/core/_services/validation.service';
import { ChequePaymentComponent } from '../cheque-payment/cheque-payment.component';

export function fiveWordsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value || '';
    const wordCount = value.trim();
    return wordCount.length > 5 ? null : { fiveWords: true };
  };
}

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.scss']
})
export class OnlinePaymentComponent implements OnInit {

  validationErrorMessage = APP_CONSTANT.VALIDATION;
  maxLength = AppConst.MAXLENGTH;
  public creditCardForm: FormGroup;
  public bankAccountForm: FormGroup;
  submitted: boolean = false;
  paymentOnline: any = 'credit';


  constructor(public dialogRef: MatDialogRef<OnlinePaymentComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private service: MainService, private fb: FormBuilder, private loader: NgxUiLoaderService, private toastr: ToastrService, private router: Router, public validationService: ValidationService) {
  }


  ngOnInit(): void {
    this.creditCardForm = this.createForm();
    this.bankAccountForm = this.createBankForm();
  }

  isCCSeleceted: boolean = false;
  optionChanged() {
    this.isCCSeleceted = true;
    this.creditCardForm.reset();
    this.bankAccountForm.reset();
  }

  createBankForm() {
    return this.fb.group({
      accountType: ['', Validators.required], // checking,savings, businessChecking
      routingNumber: ['', [Validators.required, Validators.pattern('^\s*-?[0-9]{1,9}\s*$')]], //Numeric string, up to 9 digits.
      accountNumber: ['', [Validators.required, Validators.pattern('^\s*-?[0-9]{1,17}\s*$')]], //Numeric string, up to 17 digits.
      nameOnAccount: ['', [Validators.required, Validators.maxLength(22)]] // String, up to 22 characters
    })
  }

  createForm() {
    return this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      holderName: ['', [Validators.required, fiveWordsValidator()]],
      cardNumber: ['', [Validators.required, CreditCardValidators.validateCCNumber]],
      expirationDate: ['', [Validators.required, CreditCardValidators.validateExpDate]],
      cardCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    })
  }

  get f() { return this.creditCardForm.controls; }
  get g() { return this.bankAccountForm.controls; }


  close(value: Boolean) {
    this.dialogRef.close(value);
  }

  chargeSubmit() {
    this.submitted = true;
    if (this.paymentOnline == 'credit' && this.creditCardForm.valid) {
      let expDate = this.creditCardForm.value.expirationDate.split('/');
      const [month, year] = expDate;
      const formattedDate = `${month.trim()}${year.trim()}`;
      const data = {
        card_holder_name: this.creditCardForm.value.holderName,
        email: this.creditCardForm.value.email,
        paymentType: "CREDITCARD",
        receiverId: this.data?.details?.operDetails?.emp_data?.length ? this.data?.details?.operDetails?.emp_data[0]._id : '',
        senderId: this.data?.details?.operDetails?.customerId,
        amount: Number(this.data?.total) || 0,
        creditCardNumber: this.creditCardForm.value.cardNumber.split(" ").join(''),
        expirationDate: formattedDate,
        cardCode: this.creditCardForm.value.cardCode,
        opportunityId: this.data?.details?.oppId || '',
        method: this.data?.method,
        notes: this.data?.notes,
        opportunityAmount: this.data.details?.operDetails?.totalAmount
      }
      this.pay(data);
    }
    else if (this.paymentOnline == 'bank' && this.bankAccountForm.valid) {
      const data = {
        paymentType: "BANKACCOUNT",
        receiverId: this.data?.details?.operDetails?.emp_data.length ? this.data?.details?.operDetails?.emp_data[0]._id : '',
        senderId: this.data?.details?.operDetails?.customerId,
        amount: this.data?.total || 0,
        bankAccount: {
          ...this.bankAccountForm.value,
        },
        opportunityId: this.data?.details?.oppId,
        method: 'online',
        notes: this.data?.notes,
        opportunityAmount: this.data?.details?.operDetails?.totalAmount
      }
      this.pay(data);
    }
  }

  pay(data) {
    if (this.data.sendData) {
      this.dialogRef.close(data)
    }
    else {
      this.loader.start();
      this.service.post2(data, `${API_ROUTES.PAYMENT.checkout}`).pipe().subscribe(
        {
          next: (response:any) => {
            this.loader.stop();
            if (response.status) {
              this.toastr.success(response.msg);
              this.dialogRef.close(true);
            } else {
              this.toastr.error(response.msg)
            }
          },
          error: (err:any) => {
            this.loader.stop();
            this.toastr.error(err.msg)
          }
        }
      );
    }
  }


}
