import { Injectable } from '@angular/core';
import { AppConst } from '../_constants/app.constant';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  numberWithDecimal(event: any): boolean {
    if(['+','-','e','E'].includes(event.key)) return false;
    if(event.target.value.includes('.') && event.target.value.split('.')[1].length > 1) return false;
    return event?.target?.value.match(/^\d+(\.\d)?$/);
  }

  alphabetOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (event.keyCode === 32) {
      if (event.target.value.length > 0) {
        return true;
      } else {
        if (event.keyCode === 32) {
          return false;
        }
      }
    }
    if ((charCode >= 97 && charCode <= 122) || (charCode >= 65 && charCode <= 90)) {
      return true;
    } else {
      return false;
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  alphaNumericOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (event.target.value.length > 0 && charCode == 32) {
      return true
    } else if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
      return true;
    } else if (charCode >= 48 && charCode <= 57) {
      return true;
    } else {
      return false;
    }
  }
  restictAlpabetOnly(e:any) {
    const keyCode = e.keyCode; 
		if ((e.shiftKey || (e.keyCode !== 189)) && ( (e.shiftKey||(e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode !=8 ) {
			e.preventDefault();
		} 	
  }
  passwordValidationMessage(value:any){
    let baseMsg ="Password does not match the criteria. Please enter "
    let upperCaseCharacters = /[A-Z]+/g;
    if (upperCaseCharacters.test(value) === false) {
      return `${baseMsg+'uppercase character'}`;
    }
  
    let lowerCaseCharacters = /[a-z]+/g;
    if (lowerCaseCharacters.test(value) === false) {
      return  `${baseMsg+'lowercase character'}`;
    }
  
    let numberCharacters = /[0-9]+/g;
    if (numberCharacters.test(value) === false) {
      return  `${baseMsg+'numeric character'}`;
    }
  
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (specialCharacters.test(value) === false) {
      return `${baseMsg+'special character like !@#$%&*'}`;
    }
    if (value.length< 8 || value.length>20){
      return `Password should have 8-20 characters`;
    }
    return null;
    }

    emailPhoneValidation(value:any){
      let emailValidation= /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let phoneValidation = AppConst.PHONE_PATTERN;
      if(emailValidation.test(value) == false && phoneValidation.test(value)== false){
        return `Enter a valid email or Mobile Number`;
      }
      else return null;
    }

    excludeSpecificCharacters(e:any){
      const charCode = (e.which) ? e.which : e.keyCode;
      if(!((charCode >32 && charCode<39) || (charCode >39 && charCode < 43) || (charCode >57 && charCode < 65) || (charCode >90 && charCode < 96) || (charCode >122 && charCode < 127) || charCode==43)){
        return true
      }
      return false;
    }
}
