<div class="modal-header p-0 d-flex justify-content-end">
    <button class="button-close base-btn p-0" mat-button [mat-dialog-close]="false">
        <img src='../../../../../assets/images/Group 14295.png' alt="">
    </button>
</div>
<div class="modal-body d-flex justify-content-center align-items-center">
    <ngb-carousel class="w-100" [interval]="0" activeId="1">
        <ng-template ngbSlide *ngFor="let image of data.images">
            <div class="mx-auto">
                <img *ngIf="image.includes('.png')" [src]="image" class="media">
                <video *ngIf="image.includes('.mp4')" [src]="image" class="media" controls></video>
            </div>
        </ng-template>
    </ngb-carousel>
</div>