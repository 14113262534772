<div class="modol_comp">
    <div class="modal-header pb-0 pt-0 d-flex">
        <button class="button-close" mat-button [mat-dialog-close]="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" />
            </svg>
        </button>
    </div>
    <div class="modal-body pt-0">
        <h2> {{'Check Details'}}</h2>
        <form class="" [formGroup]="chargeForm">
            <!-- <div class="payment">
                <span>Total Amount</span>
                <span>{{'$'+data?.data?.balanceRemaining}}</span>
            </div> -->
            <div class="row mt-0">

                <div class="form-group col-md-12">
                    <label class="">Payee Name</label>
                    <input type="text" class="form-control mt-3" formControlName="payeeName"  [ngClass]="{ 'is-invalid': submitted && f['payeeName'].errors}"  (keypress)="validationService.alphabetOnly($event)"  />
                    <div *ngIf="submitted && f['payeeName'].errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="submitted && f['payeeName'].errors['required']">
                            {{'Payee name is required.'}}
                        </div>
                    </div>
                </div>  
                <div class="form-group col-md-12">
                    <label class="">Bank Name</label>
                    <input type="text" class="form-control mt-3" formControlName="bankName"  [ngClass]="{ 'is-invalid': submitted && f['bankName'].errors}"  (keypress)="validationService.alphabetOnly($event)"  />
                    <div *ngIf="submitted && f['bankName'].errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="submitted && f['bankName'].errors['required']">
                            {{'Bank name is required.'}}
                        </div>
                    </div>
                </div> 
                <div class="form-group col-md-12">
                    <label class="">Check Number</label>
                    <input type="text" class="form-control mt-3" formControlName="chequeNumber"  [ngClass]="{ 'is-invalid': submitted && f['chequeNumber'].errors}"  (keypress)="validationService.numberOnly($event)"  />
                    <div *ngIf="submitted && f['chequeNumber'].errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="submitted && f['chequeNumber'].errors['required']">
                            {{'Check number is required.'}}
                        </div>
                    </div>
                </div>     
                
                <div class="form-group col-md-12">
                    <label class="">Date</label>
                    <div class="date mt-3" [ngClass]="{ 'is-invalid': submitted && f['date'].errors}">
                        <input matInput  [matDatepicker]="dp3" (click)="dp3.open()" formControlName="date" [ngClass]="{ 'is-invalid': submitted && f['date'].errors}" >
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3></mat-datepicker>
                    </div>
                    <div *ngIf=" submitted && f['date'].errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="submitted && f['date'].errors['required']">
                            Date is required.
                        </div>
                    </div>
                </div>

            </div>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn cancel" [mat-dialog-close]="false">Cancel</button>
        <!-- <button type="submit" class="btn delete-btn" *ngIf="data.type==2">Delete</button> -->
        <button type="submit" class="btn delete"
            (click)="chargeSubmit()">{{'Pay'}}</button>
    </div>

</div>