<div class="pagination d-flex justify-content-end">
    <pagination-controls (pageChange)="pageChanged($event)"
    (pageBoundsCorrection)="pageChanged($event)"
    [maxSize]="12"
    [directionLinks]="true"
    [autoHide]="true"
    [responsive]="false"
    previousLabel="&lsaquo;"
    nextLabel="&rsaquo;">
    </pagination-controls>
</div>
