<div class="modol_comp">
    <div class="modal-header pb-0 pt-0 d-flex">
        <button class="button-close" mat-button [mat-dialog-close]="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" />
            </svg>
        </button>
    </div>
    <div class="modal-body pt-0">
        <h2> {{'Total payment to be made'}}</h2>
        <form class="" [formGroup]="chargeForm">
            <div class="payment">
                <span>Total Amount</span>
                <span>{{fixedTotal()}}</span>
            </div>
            <div class="row mt-0">

                <div class="form-group col-md-12">
                    <label class="">Payment</label>
                    <mat-select name="" id="" class="form-control mt-3" (selectionChange)="setAmount($event)" [ngClass]="{ 'is-invalid': submitted && f['paymentType'].errors}" formControlName="paymentType" placeholder="Select">
                        <mat-option value="partial">Partial</mat-option>
                        <mat-option value="complete">Complete</mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f['paymentType'].errors" class="invalid-feedback">
                        <div *ngIf="submitted && f['paymentType'].errors['required']">
                            {{'Select the payment type.'}}</div>
                    </div>
                </div>     
                <div class="form-group col-md-12">
                    <label class="">Amount</label>
                        <div class="dollar">
                            <span class="">$</span>
                            <input type="text" [readonly]="chargeForm.value.paymentType == 'partial' ? false: true" [min]="0" [max]="data.data.balanceRemaining" class="form-control mt-3" formControlName="amount" (keypress)="validationService.numberWithDecimal($event)"
                        [ngClass]="{ 'is-invalid': submitted && f['amount'].errors}" placeholder="Enter Amount" (blur)="setTodecimal()" />
                        </div>
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="submitted && f['amount'].errors['required']">
                            {{'Amount is required.'}}
                        </div>
                    </div>
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="submitted && f['amount'].errors['max']">
                            {{'Amount exceeded.'}}
                        </div>
                    </div>
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="submitted && f['amount'].errors['min']">
                            {{'Amount can not be negative.'}}
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label class="">Method</label>
                    <mat-select name="" id="" class="form-control mt-3" [ngClass]="{ 'is-invalid': submitted && f['method'].errors}" formControlName="method" placeholder="Select">
                        <mat-option [value]="item?.value" *ngFor="let item of getMethod()">{{item?.key}}</mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="submitted && f['amount'].errors['required']">
                            {{'Method is required.'}}
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label class="">Notes</label>
                    <textarea type="text" class="form-control mt-3" formControlName="notes"
                        [ngClass]="{ 'is-invalid': submitted && f['notes'].errors}" placeholder="Enter notes" ></textarea>
                </div>

            </div>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn cancel" [mat-dialog-close]="false">Cancel</button>
        <button type="submit" class="btn delete"
            (click)="chargeSubmit()">{{'Pay'}}</button>
    </div>

</div>