import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private http:HttpClient) { }
  post2(payload: any, url: string): Observable<any> {
    console.log(payload,"payload");
    console.log(url,"url");
    
   return this.http.post<any>(url, payload).pipe(
     map(resp => resp),
     catchError(err => {
       return throwError(err.error);
     }),
   )
 }
  post(payload: any, url: string): Observable<any> {
     console.log(payload,"payload");
     console.log(url,"url");
     
    return this.http.post<any>(url, payload).pipe(
      map(resp => resp),
      catchError(err => {
        return err;
      }),
    )
  }

  // post(path: string, body: Object = {}): Observable<any> {
  //   return this.http.post(`${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors));
  // }

  put(payload: any, url: string): Observable<any> {
   
    return this.http.put<any>(url, payload).pipe(
      catchError(err => {
        return err;
      }),
      map(resp => resp),
    )
  }

  patch(path: string, body: any): Observable<any> {
    return this.http.patch(`${path}`, JSON.stringify(body)).pipe(catchError(err => throwError(err.error)));
  }

  get(payload:any,url: any): Observable<any> {
    let params = new HttpParams()
    params = params.appendAll(payload)
    return this.http.get(url,{params: params}).pipe(
      catchError(err => {
        return err;
      }),
        map((response: any) => {
          return response;
        }),
       
      )    
  }
}
