<div class="modol_comp">
    <div class="modal-header pb-0 pt-0 d-flex">
        <button class="button-close" mat-button [mat-dialog-close]="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" />
            </svg>
        </button>
    </div>
    <div class="modal-body pt-0">
        <h2> {{data?.heading }}</h2>
        <div class="d-flex flex-wrap align-items-center justify-content-center main">
            <!-- <div class="my-2">
               <div>
                <label class="card d-flex align-items-center justify-content-around" for="cash" [ngClass]="paymentType==1 ? 'active': ''">
                    <img class="checked-type" src="assets/images/tick-svgrepo-com.svg" width="20" height="20"/>
                    <div>
                        <div>
                            <svg-icon src="/assets/images/cash.svg" ></svg-icon>
                        </div>
                        <div>
                            <h4>Cash</h4>
                        </div>
                    </div>
                </label>
                <input id="cash" [(ngModel)]="paymentType" [ngModelOptions]="{standalone: true}" type="radio" name="payment" value="1" hidden/>
               </div>
            </div> -->
            <div class="my-2">
                <div>
                    <label class="card d-flex align-items-center justify-content-around" for="online" [ngClass]="paymentType==2 ? 'active': ''">
                        <img class="checked-type" src="assets/images/tick-svgrepo-com.svg" width="20" height="20"/>
                        <div>
                            <div>
                                <svg-icon src="/assets/images/online.svg" ></svg-icon>
                            </div>
                            <div>
                                <h4>Pay Online</h4>
                            </div>
                        </div>
                    </label>
                    <input id="online" [(ngModel)]="paymentType" [ngModelOptions]="{standalone: true}" type="radio" name="payment" value="2" hidden/>
                </div>
            </div>
            
            <!-- <div  class="my-2">
                <div>
                    <label class="card d-flex align-items-center justify-content-around" for="cheque" [ngClass]="paymentType==3 ? 'active': ''">
                        <img class="checked-type" src="assets/images/tick-svgrepo-com.svg" width="20" height="20"/>
                        <div>
                            <div>
                                <svg-icon src="/assets/images/cheque.svg" ></svg-icon>
                            </div>
                            <div>
                                <h4>Check</h4>
                            </div>
                        </div>
                    </label>
                    <input id="cheque" [(ngModel)]="paymentType" [ngModelOptions]="{standalone: true}" type="radio" name="payment" value="3" hidden/>
                </div>
            </div> -->
        </div>
        <div class="refund"  *ngIf="data.operDetails.emp_data && data.operDetails.emp_data.length">
                <label class="card d-flex align-items-center justify-content-around" for="refund" [ngClass]="paymentType==4 ? 'active': ''">
                    <img class="checked-type" src="assets/images/tick-svgrepo-com.svg" width="20" height="20"/>
                    <div>
                        <div class="d-flex justify-content-center align-items-center">
                            <svg-icon src="/assets/images/refund.svg" ></svg-icon>  <h4 class="mx-2">Refund</h4>
                        </div>
                    </div>
                </label>
                <input id="refund" [(ngModel)]="paymentType" [ngModelOptions]="{standalone: true}" type="radio" name="payment" value="4" hidden/>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn cancel" [mat-dialog-close]="false">Cancel</button>
        <button type="submit" class="btn delete" (click)="pay()">{{ 'Pay'}}</button>
    </div>

</div>