import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { MessagingService } from './core/_services/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Service Galaxy Customer';
  message: any;
  constructor(private messagingService: MessagingService){
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
  }
  ngOnInit(){
   
  }
}
