import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteConstant } from './core/_constants/route.constant';
import { AuthGuard } from './core/_guards/auth.guard';
import { PublicComponent } from './views/theme/public/public.component';
import { PUBLIC_ROUTES } from './views/theme/public/public.route';
import { SecureComponent } from './views/theme/secure/secure.component';
import { SECURE_ROUTES } from './views/theme/secure/secure.route';

const routes: Routes = [
  { path: '', redirectTo: `${RouteConstant.Login}`, pathMatch: 'full' },
  { path: '', component: PublicComponent, data: { title: 'Public Views' }, children: PUBLIC_ROUTES },
  { path: '', component: SecureComponent, data: { title: 'Secure Views' }, children: SECURE_ROUTES, canActivate: [AuthGuard]  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true ,preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
