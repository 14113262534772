<div class="modol_comp">
    <div class="modal-header pb-0 pt-0 d-flex">
        <button class="button-close" mat-button [mat-dialog-close]="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" />
            </svg>
        </button>
    </div>
    <div class="modal-body pt-0">
        <h2> {{'Select Job/Opportunity'}}</h2>
        <div class="opportunity-list">
            <div class="table-responsive scroll-hide">
                <table class="table ">
                    <thead class="p-2">
                        <tr>
                            <th>Opp/Job Id</th>
                            <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data.opp">
                            <td>{{item?.opportunityId}}</td>
                            <td><button class="payment-btn" (click)="select(item)">Select</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
      
    </div>
</div>