import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppConst } from 'src/app/core/_constants/app.constant';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  public dateFormat = AppConst.DATE_FORMAT
  @Input() preview:any;
  @Input() employee: any;
  @Input() schedules:any
  constructor() { }
  ngOnInit(): void {
    
  }
  getEndTime(startTime, duration){
    if(startTime && duration){
      return this.calcEndTime(startTime,  duration)
    }
    else{
      return "NA"
    }
  }
    calcEndTime(startTime, duration) {
    return moment(startTime).add(duration, 'minutes').format('hh:mm A');
  }

  getStatusClass(status: number,isReschedule: number): string {
    if ((status === 15 && isReschedule)||status === 15) {
      return 'red-btn';
    } 
    else if(isReschedule){
      return 'purple-btn';
    }
    else if(status===2.7){
      return 'blue-btn';
    }
    else if(status===2.6){
      return 'orange-btn'
    }
    else {
      return 'green-btn';
    }
  }
  getStatusLabel(status: number, isReschedule: number): string {
    if ((isReschedule && status === 15)||status===15) {
      return 'Unscheduled';
    } else if (status === 2.7) {
      return 'Occured';
    } 
    else if(status===2.6){
      return 'Missed';
    }
    else if (isReschedule) {
      return 'Rescheduled';
    } else {
      return 'Scheduled';
    }
  }
  
}
