import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { CommonService } from 'src/app/core/_services/common.service';
import { LocalStorageProvider } from 'src/app/core/_services/storage.service';
import { ServiceProvidersComponent } from '../../partials/dialogs/service-providers/service-providers.component';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss']
})
export class SecureComponent implements OnInit {
  serviceProviderDetail: any;

  constructor(private loader: NgxUiLoaderService,public commonService: CommonService,private storageService: LocalStorageProvider,private dialog: MatDialog,private router: Router) {
    
  }

  ngOnInit(): void {
    this.commonService.serviceProviderData$.subscribe(data=>{
      this.serviceProviderDetail = data;
    });
    this.serviceProviderDetail = this.storageService.getItem('ServiceProvider');
  }

  openModal(){
    this.loader.start();
    const dialogRef = this.dialog.open(ServiceProvidersComponent,{
      maxHeight: '100vh',
      width:'523px',
      data: {
        title: `Select Service Provider`,
        sayNo: `Cancel`,
        sayYes: `Submit`,
        provider: ''
      }
    });
    this.loader.stop();
    // dialogRef.afterClosed().subscribe((confirm)=> {
    //   // if (confirm !== undefined && confirm !== null && confirm) {
    //   //   this.router.navigate([`${RouteConstant.Dashboard}`])
    //   //   // return confirm;
    //   //   // this.commonService.setserviceProviderData(confirm);
    //   // }
    // })
  }

}
