import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageProvider } from '../_services/storage.service';
import { AuthService } from '../_services/auth.service';
import { RouteConstant } from '../_constants/route.constant';
import { MainService } from '../_services/main.service';
import { API_ROUTES } from '../_constants/api-route.constant';
import { CommonService } from '../_services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private storageService: LocalStorageProvider) { }
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let token = this.storageService.getItem('token');
    let cUser = this.storageService.getItem('CurrentUser');
    if (this.authService.isLoggedIn && token != undefined && token != null && cUser != undefined && cUser != null) {
      return true;
    }
    this.router.navigate(['/'])
    return false;
  }

}

@Injectable({
  providedIn: 'root'
})
export class CanLoginActivate implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private storageService: LocalStorageProvider) { }

  canActivate(): boolean {
    let token = this.storageService.getItem('token');
    if (!token) {
      return true;
    }
    this.router.navigate([`${RouteConstant.Dashboard}`]);
    return false;
  }
}



@Injectable({
  providedIn: 'root'
})
export class CanLoginWithParamActivate implements CanActivate {
  constructor(private router: Router, private authService: AuthService,private service: MainService, private storageService: LocalStorageProvider,private commonService: CommonService,private loader: NgxUiLoaderService, private toastr: ToastrService) { }
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    
    let token = this.storageService.getItem('token');
    let cUser = this.storageService.getItem('CurrentUser');
    if (this.authService.isLoggedIn && token != undefined && token != null && cUser != undefined && cUser != null) {
      const data = {
        str: state.url.split('/')[2]
      }
      this.loader.start();
      this.service.get(data,`${API_ROUTES.ACCOUNT.verifyMail}`).pipe().subscribe({
        next: (response) => {
          if(response.status){
            const data = response.data;
            const serviceProviderData = {
              companyName: data.serviceProviderCompanyName,
              fullName: data.serviceProviderName,
              profilePic: data.serviceProviderProfilePic,
              _id: data.serviceProviderId
            }
            if(data.serviceProviderId !== cUser.selectedServiceProvider._id){
              this.updateLastServiceProvider(serviceProviderData,data)
            }else{
              this.router.navigate([`${RouteConstant.Dashboard+'/'+RouteConstant.Opportunities+'/'+RouteConstant.opportunityDetail}`],{queryParams:{oppId:data.oppId}});
            }
            this.loader.stop();
          }else{
            this.loader.stop();
            this.toastr.error(response.msg)
          }
          return false;

        },
        error: (err)=> {

          this.loader.stop();
          this.toastr.error(err.msg);
          return false;

        },
        complete: () => this.loader.stop()
      })
      // this.router.navigate([`${RouteConstant.Dashboard+'/'+RouteConstant.Opportunities+'/'+RouteConstant.opportunityDetail}`],{queryParams:{oppId:'fhhfhhfh'}});
    }
    // this.router.navigate(['/'])
    return true;
  }
  updateLastServiceProvider(data:any,oppIdData:any){
    this.service.post(data,`${API_ROUTES.ACCOUNT.changeProvider}`).pipe().subscribe({
      next :(response) => {
      if(response.status){
        this.commonService.setserviceProviderData(data);
        this.router.navigate([`${RouteConstant.Dashboard+'/'+RouteConstant.Opportunities+'/'+RouteConstant.opportunityDetail}`],{queryParams:{oppId:oppIdData.oppId}});
      }else{
        this.toastr.error(response.msg);
      }
      this.loader.stop();
    },
    error: (err)=> {
      this.loader.stop();
      this.toastr.error(err.msg)
    }
  }) 
  }

}

