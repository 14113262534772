import { Routes } from '@angular/router';
import { RouteConstant } from 'src/app/core/_constants/route.constant';

export const PUBLIC_ROUTES: Routes = [
    { path: ``, redirectTo: `${RouteConstant.Login}`, pathMatch: 'full' },
    {
        path: ``,
        data: { preload: true },
        loadChildren: () => import('../../pages/auth/auth.module').then(mod => mod.AuthModule)
    },
];