import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs'
@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    constructor(private angularFireMessaging: AngularFireMessaging,private toastr: ToastrService) {
        this.angularFireMessaging.messages.subscribe(
            (_messaging:any) => {
              _messaging['onMessage'] = _messaging['onMessage'].bind(_messaging);
              _messaging['onTokenRefresh'] = _messaging['onTokenRefresh'].bind(_messaging);
            //   _messaging['onBackgroundMessage'] = _messaging['onBackgroundMessage'].bind(_messaging);
            }
          )
    }
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token:any) => {
                if(token){
                }
                else {
                    console.error('Unable to get permission to notify.', '');
                }
                
                
            },
            (err:any) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload:any) => {
                // console.log("new message received. ", payload);
                this.currentMessage.next(payload);
            })
    }
}