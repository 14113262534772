export const environment = {
    production: false,
    apiBaseUrl: "https://devcustomer.servicegalaxy.com:3006/",
    // apiBaseUrl: "https://qacustomer.servicegalaxy.com:3006/",
    bucketurl: 'https://service-galaxy-dev.s3.us-east-2.amazonaws.com',
    bucketname: "service-galaxy-dev",
    firebase: {
      apiKey: "AIzaSyC2UyybrDbece03B9gLCOc1mFlRIrG7bgk",
      authDomain: "service-galaxy.firebaseapp.com",
      databaseURL: "https://service-galaxy-default-rtdb.firebaseio.com",
      projectId: "service-galaxy",
      storageBucket: "service-galaxy.appspot.com",
      messagingSenderId: "466475764062",
      appId: "1:466475764062:web:1d39f2fccf6fba129467cf",
      measurementId: "G-JVZL9QCTSJ"
    }
  };