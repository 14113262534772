import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { MainService } from 'src/app/core/_services/main.service';
import { PaymentsComponent } from '../payments/payments.component';

@Component({
  selector: 'app-payment-opp-select',
  templateUrl: './payment-opp-select.component.html',
  styleUrls: ['./payment-opp-select.component.scss']
})
export class PaymentOppSelectComponent implements OnInit {

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<PaymentsComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private service: MainService,private loader: NgxUiLoaderService, private toastr: ToastrService,private router: Router) { 
  }

  ngOnInit(): void {
  }

  select(opportunity){
    if(opportunity.isOpportunity){
      this.router.navigate([`${RouteConstant.Dashboard + '/' + RouteConstant.Opportunities + '/' + RouteConstant.opportunityDetail}`], { queryParams: { oppId: opportunity._id, payment: true } })
    }
    else{
      this.router.navigate([`${RouteConstant.Dashboard + '/' + RouteConstant.Jobs + '/' + RouteConstant.JobDetail}`], { queryParams: { jobId: opportunity._id, payment: true } })
    }
    this.dialogRef.close();
  }

}
