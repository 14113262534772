import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreditCardValidators } from 'angular-cc-library';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { AppConst, APP_CONSTANT } from 'src/app/core/_constants/app.constant';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { MainService } from 'src/app/core/_services/main.service';
import { ValidationService } from 'src/app/core/_services/validation.service';

@Component({
  selector: 'app-online-payments',
  templateUrl: './online-payments.component.html',
  styleUrls: ['./online-payments.component.scss']
})

export class OnlinePaymentsComponent implements OnInit {

  validationErrorMessage = APP_CONSTANT.VALIDATION;
  maxLength = AppConst.MAXLENGTH;
  public creditCardForm: FormGroup;
  public bankAccountForm: FormGroup;
  submitted: boolean = false;
  paymentOnline: any = 'credit';


  constructor(public dialogRef: MatDialogRef<OnlinePaymentsComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private service: MainService, private fb: FormBuilder, private loader: NgxUiLoaderService, private toastr: ToastrService, private router: Router, public validationService: ValidationService) {
  }


  ngOnInit(): void {
    console.log(this.data,"data in online payments")
    this.creditCardForm = this.createForm();
    this.bankAccountForm = this.createBankForm();
  }

  optionChanged() {
    this.creditCardForm.reset();
    this.bankAccountForm.reset();
  }

  createBankForm() {
    return this.fb.group({
      accountType: ['', Validators.required], // checking,savings, businessChecking
      routingNumber: ['', [Validators.required, Validators.pattern('^\s*-?[0-9]{1,9}\s*$')]], //Numeric string, up to 9 digits.
      accountNumber: ['', [Validators.required, Validators.pattern('^\s*-?[0-9]{1,17}\s*$')]], //Numeric string, up to 17 digits.
      nameOnAccount: ['', [Validators.required, Validators.maxLength(22)]] // String, up to 22 characters
    })
  }

  createForm() {
    return this.fb.group({
      cardNumber: ['', [Validators.required, CreditCardValidators.validateCCNumber]],
      expirationDate: ['', [Validators.required,CreditCardValidators.validateExpDate]],
      cardCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    })
  }

  get f() { return this.creditCardForm.controls; }
  get g() { return this.bankAccountForm.controls; }


  close(value: Boolean) {
    this.dialogRef.close(value);
  }

  chargeSubmit() {
    this.submitted = true;
    if (this.paymentOnline == 'credit' && this.creditCardForm.valid) {
      let expDate = this.creditCardForm.value.expirationDate.split('/')[1].trim();
      expDate = expDate.length == 4 ? expDate : '20'+expDate
      const data = {
        paymentType: "CREDITCARD",
        receiverId: this.data?.details?.emp_data ? this.data?.details?.emp_data._id: '',
        senderId: this.data?.details?.customerId,
        amount: Number(this.data?.total) || 0,
        creditCardNumber : this.creditCardForm.value.cardNumber.split(" ").join(''),
        expirationDate: expDate + '-' + this.creditCardForm.value.expirationDate.split('/')[0].trim() ,
        cardCode: this.creditCardForm.value.cardCode,
        opportunityId: this.data.details._id,
        method: this.data?.method,
        notes: this.data.notes,
        opportunityAmount:  this.data.details?.totalAmount
      }
      this.pay(data);
    }
    else if (this.paymentOnline == 'bank' && this.bankAccountForm.valid) {
      const data = {
        paymentType: "BANKACCOUNT",
        receiverId: this.data?.details?.emp_data.length? this.data?.details?.emp_data[0]._id: '',
        senderId: this.data?.details?.customerId,
        amount: this.data?.total || 0,
        bankAccount:{
          ...this.bankAccountForm.value,
        },
        opportunityId: this.data.details._id,
        method: 'online',
        notes: this.data.notes,
        opportunityAmount:  this.data.details?.totalAmount
      }
      this.pay(data);
    }
  }

  pay(data) {
    this.loader.start();
    this.service.post(data,`${API_ROUTES.PAYMENT.checkout}`).subscribe({
      next: (response) => {
        this.loader.stop();
        if (response.status) {
          this.toastr.success(response.msg);
          this.dialogRef.close(true)
        }
        else{
          this.toastr.error(response.data?.errors[0]?.errorText);
        }
      },
      error: (err) => {
        this.loader.stop();
        this.toastr.error(err.msg);
      },
      complete: () => this.loader.stop()
    })
  }


}
