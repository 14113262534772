import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeModule } from './views/theme/theme.module';
import {  NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { MatSelectModule } from '@angular/material/select'; 
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { AuthGuard, CanLoginActivate, CanLoginWithParamActivate } from './core/_guards/auth.guard';
import { AppHttpInterceptor } from './core/_interceptor/interceptor';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { ImageDragDirective } from './core/_directives/image-drag.directive';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { MessagingService } from './core/_services/messaging.service';
import { AsyncPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AngularSvgIconModule } from 'angular-svg-icon';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#264A9E',
  fgsColor: '#264A9E',
  pbColor: '#264A9E',
}

@NgModule({
  declarations: [
    AppComponent,
    ImageDragDirective,
    // JoinArrayPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ThemeModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
    }),
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularSvgIconModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),

  ],
  providers: [
    CanLoginActivate,
    AuthGuard,
    CookieService,
    CanLoginWithParamActivate,
   { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
   MessagingService,AsyncPipe
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
