import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Output() setPageNumber = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }
  pageChanged(event: any) {
    this.setPageNumber.emit(event);
  }

}
