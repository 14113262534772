import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Validators } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { APP_CONSTANT, AppConst } from 'src/app/core/_constants/app.constant';
import { MainService } from 'src/app/core/_services/main.service';
import { ValidationService } from 'src/app/core/_services/validation.service';
import { ChequePaymentComponent } from '../cheque-payment/cheque-payment.component';
import { OnlinePaymentComponent } from '../online-payment/online-payment.component';
import { PaymentStyleComponent } from '../payment/payment.component';

@Component({
  selector: 'app-refund-dialog',
  templateUrl: './refund-dialog.component.html',
  styleUrls: ['./refund-dialog.component.scss']
})
export class RefundDialogComponent implements OnInit {
  validationErrorMessage= APP_CONSTANT.VALIDATION;
  maxLength = AppConst.MAXLENGTH;
  public chargeForm: FormGroup;
  submitted: boolean = false;
  methodArray: any = [
    {key: 'In Person', value: 'In Person', type: [1,2,3]},
    {key: 'Mail', value: 'Mail', type: [1,3]},
    {key: 'Phone', value: 'Phone', type: [2]}
  ]

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<PaymentStyleComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private service: MainService, private fb: FormBuilder,private loader: NgxUiLoaderService, private toastr: ToastrService,private router: Router,public validationService: ValidationService) { 
  }


  ngOnInit(): void {
    this.chargeForm = this.createForm();
  }
  
  createForm(){
    return this.fb.group({
      notes: [''],
      paymentType: ['', Validators.required],
      amount: ['',Validators.required],
      method: ['',Validators.required]
    })
  }

  get f() { return this.chargeForm.controls; }

  close(value:Boolean){
    if(this.chargeForm.valid){
      this.dialogRef.close(value);
    }
  }

  chargeSubmit(){
    this.submitted = true;
    if(this.chargeForm.valid){
      if(this.chargeForm.value.paymentType == 1){
        const data = {
          opportunityId: this.data.data.oppId,
          method: this.chargeForm.value.method,
          notes: this.chargeForm.value.notes,
          total: Number(this.chargeForm.value.amount),
          paymentType: 'cash',
          senderId : this.data?.data?.operDetails?.emp_data.length? this.data?.data?.operDetails?.emp_data[0]._id : '',
          receiverId : this.data?.data?.operDetails?.customerId,
          opportunityAmount:  this.data.data?.operDetails.totalAmount,
          isRefund : true
        }
        this.pay(data)
      }
      else if(this.chargeForm.value.paymentType == 2){
        const dialogRef = this.dialog.open(OnlinePaymentComponent, {
          maxHeight: '100vh',
          width: '700px',
          maxWidth: '100%',
          panelClass: 'custom-modalbox',
          disableClose: true,
          data: {
            opportunityId: this.data.data.oppId,
            details: this.data.data,
            method: this.chargeForm.value.method,
            notes: this.chargeForm.value.notes,
            total: this.chargeForm.value.amount,
            paymentType: 'online',
            isRefund : true
          }
        });
        dialogRef.afterClosed().subscribe((confirm: any) => {
          if (confirm !== undefined && confirm !== null && confirm) {
            this.dialogRef.close(true);
          }
        });
      }
      else if(this.chargeForm.value.paymentType == 3){
        const dialogRef = this.dialog.open(ChequePaymentComponent, {
          maxHeight: '100vh',
          width: '700px',
          maxWidth: '100%',
          panelClass: 'custom-modalbox',
          disableClose: true,
          data: {
            opportunityId: this.data.data.oppId,
            details: this.data.data,
            method: this.chargeForm.value.method,
            notes: this.chargeForm.value.notes,
            total: Number(this.chargeForm.value.amount),
            paymentType: 'check',
            isRefund : true
          }
        });
        dialogRef.afterClosed().subscribe((confirm: any) => {
          if (confirm !== undefined && confirm !== null && confirm) {
            this.dialogRef.close(true);
          }
        });
      }
    }
  }

  pay(data){
    this.loader.start();
    this.service.post(`${API_ROUTES.OPPORTUNITY.addPayment}`,data).subscribe({
      next: (response)=>{
        if(response.status){
          this.loader.stop();
          this.toastr.success(response.msg);
          this.dialogRef.close(true)
        }
      },
      error: (err)=> {
        this.loader.stop();
        this.toastr.error(err.msg);
      },
      complete: ()=> this.loader.stop()
    })
  }

  
  setAmount(event){
    if(event.value=='complete'){
        this.chargeForm.patchValue({
          amount: Number(this.data?.data?.balanceRemaining).toFixed(2)
        })
    }else{
      this.chargeForm.patchValue({
        amount: ''
      })
    }
  }

  getMethod(){
    let methodArray =  this.methodArray.filter((x: any)=> x.type.includes(Number(this.chargeForm.value.paymentType)))
    return methodArray
  }

  setTodecimal(): any {
    if(this.chargeForm.value.amount){
      this.chargeForm.patchValue({amount: Number(this.chargeForm.value.amount).toFixed(2)})
    }
  }

  fixedTotal(){
   if(this.data?.data && this.data?.data?.balanceRemaining){
    return '$' + Number(this.data?.data?.balanceRemaining).toFixed(2)
   }
   return '$0.00'
  }


}
