import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MainService } from 'src/app/core/_services/main.service';
import { ValidationService } from 'src/app/core/_services/validation.service';
import { PaymentStyleComponent } from '../payment/payment.component';
import { RefundDialogComponent } from '../refund-dialog/refund-dialog.component';

@Component({
  selector: 'app-choose-payment-component',
  templateUrl: './choose-payment-component.component.html',
  styleUrls: ['./choose-payment-component.component.scss'],
})
export class ChoosePaymentComponentComponent implements OnInit {
  paymentType = 1;
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ChoosePaymentComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: MainService,
    private fb: FormBuilder,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private router: Router,
    public validationService: ValidationService
  ) {}

  ngOnInit(): void {}

  pay(){
    if(this.paymentType && this.paymentType < 4){
      const dialogRef = this.dialog.open(PaymentStyleComponent,{
        maxHeight: '100vh',
        width: '700px',
        maxWidth: '100%',
        panelClass: 'custom-modalbox',
        disableClose: true,
        data: {
          type: this.paymentType,
          data: this.data,
        }
      })
      dialogRef.afterClosed().subscribe((confirm: any) => {
        if (confirm !== undefined && confirm !== null && confirm) {
          this.dialogRef.close(true);
        }
      });
    }
    else if(this.paymentType && this.paymentType == 4){
      const dialogRef = this.dialog.open(RefundDialogComponent,{
        maxHeight: '100vh',
        width: '700px',
        maxWidth: '100%',
        panelClass: 'custom-modalbox',
        disableClose: true,
        data: {
          type: this.paymentType,
          data: this.data,
        }
      })
      dialogRef.afterClosed().subscribe((confirm: any) => {
        if (confirm !== undefined && confirm !== null && confirm) {
          this.dialogRef.close(true);
        }
      });
      }
    }
  
  
}
