<div class="modol_comp">
    <div class="modal-header pb-0 pt-0 d-flex">
        <button class="button-close" mat-button [mat-dialog-close]="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" />
            </svg>
        </button>
    </div>
    <div class="modal-body pt-0">
        <h2> {{'Select Payment Option'}}</h2>

        <form>
            <div class="row mt-0">
                <div class="form-group col-md-12">
                    <label class="">Payment Method</label>
                    <mat-select name="" id="" class="form-control mt-3" [(ngModel)]="paymentOnline"
                        (ngModelChange)='optionChanged()' [ngModelOptions]="{standalone: true}" placeholder="Select">
                        <mat-option [value]="'credit'">Credit Card</mat-option>
                        <mat-option [value]="'bank'">Bank Account</mat-option>
                    </mat-select>
                </div>
            </div>
        </form>

        <form class="" [formGroup]="creditCardForm" *ngIf="paymentOnline == 'credit'">
            <div class="row mt-0">
                <div class="form-group col-md-12">
                    <label class="">Card Number</label>
                    <input id="cc-number" class="form-control mt-3" formControlName="cardNumber" type="tel"
                        autocomplete="cc-number" ccNumber
                        [ngClass]="{ 'is-invalid': submitted && f['cardNumber'].errors}">
                    <div *ngIf="submitted && f['cardNumber'].errors" class="invalid-feedback" style="display: block;">
                        <ng-container *ngIf="f['cardNumber'].errors['required']; else elseTemplate">
                            {{'Card Number is required.'}}
                        </ng-container>
                        <ng-template #elseTemplate>
                            {{'Enter a valid card number.'}}
                        </ng-template>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label class="">Expiry Date</label>
                    <input id="cc-exp-date" class="form-control mt-3" formControlName="expirationDate" type="tel"
                        autocomplete="cc-exp" ccExp
                        [ngClass]="{ 'is-invalid': submitted && f['expirationDate'].errors}">
                    <div *ngIf="submitted && f['expirationDate'].errors" class="invalid-feedback"
                        style="display: block;">
                        <ng-container *ngIf="f['expirationDate'].errors['required']; else elseTemplate">
                            {{'Expiry Date is required.'}}
                        </ng-container>
                        <ng-template #elseTemplate>
                            {{'Enter a valid expiry date'}}
                        </ng-template>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label class="">CVC</label>
                    <input id="cc-number" class="form-control mt-3" formControlName="cardCode" type="tel"
                        autocomplete="cc-number" ccNumber 
                        [ngClass]="{ 'is-invalid': submitted && f['cardCode'].errors}">
                    <div *ngIf="submitted && f['cardCode'].errors" class="invalid-feedback" style="display: block;">
                        <ng-container *ngIf="f['cardCode'].errors['required']; else elseTemplate">
                            {{'CVC is required'}}
                        </ng-container>
                        <ng-template #elseTemplate>
                            {{'Enter a valid CVC.'}}
                        </ng-template>
                    </div>
                </div>
            </div>

        </form>

        <form class="" [formGroup]="bankAccountForm" *ngIf="paymentOnline == 'bank'">
            <div class="row mt-0">
                <div class="form-group col-md-12">
                    <label class="">Account Type</label>
                    <mat-select name="" id="" class="form-control mt-3" formControlName="accountType"
                        [ngClass]="{ 'is-invalid': submitted && g['accountType'].errors}" placeholder="Select">
                        <mat-option [value]="'checking'">Checking</mat-option>
                        <mat-option [value]="'savings'">Savings</mat-option>
                        <mat-option [value]="'businessChecking'">Business Checking</mat-option>
                    </mat-select>
                    <div *ngIf="submitted && g['accountType'].errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="submitted && g['accountType'].errors['required']">
                            {{'Account Type is required.'}}
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label class="">Routing Number</label>
                    <input class="form-control mt-3" formControlName="routingNumber" type="tel"
                        [ngClass]="{ 'is-invalid': submitted && g['routingNumber'].errors}" maxlength="9">
                    <div *ngIf="submitted && g['routingNumber'].errors" class="invalid-feedback"
                        style="display: block;">
                        <div *ngIf="submitted && g['routingNumber'].errors['required']">
                            {{'Routing Number is required.'}}
                        </div>
                        <div *ngIf="submitted && g['routingNumber'].errors['pattern']">
                            {{'Enter a valid Routing Number'}}
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label class="">Account Number</label>
                    <input class="form-control mt-3" formControlName="accountNumber" type="tel"
                        [ngClass]="{ 'is-invalid': submitted && g['accountNumber'].errors}" maxlength="17">
                    <div *ngIf="submitted && g['accountNumber'].errors" class="invalid-feedback"
                        style="display: block;">
                        <div *ngIf="submitted && g['accountNumber'].errors['required']">
                            {{'Account Number is required'}}
                        </div>
                        <div *ngIf="submitted && g['accountNumber'].errors['pattern']">
                            {{'Enter a valid Account Number'}}
                        </div>
                    </div>
                </div>

                <div class="form-group col-md-12">
                    <label class="">Name on Account</label>
                    <input class="form-control mt-3" formControlName="nameOnAccount" type="text"
                        [ngClass]="{ 'is-invalid': submitted && g['nameOnAccount'].errors}" maxlength="22">
                    <div *ngIf="submitted && g['nameOnAccount'].errors" class="invalid-feedback"
                        style="display: block;">
                        <div *ngIf="submitted && g['nameOnAccount'].errors['required']">
                            {{'Name is required'}}
                        </div>
                        <div *ngIf="submitted && g['nameOnAccount'].errors['maxlength']">
                            {{'Maximum of 22 characters are allowed'}}
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn cancel" [mat-dialog-close]="false">Cancel</button>
        <button type="submit" class="btn delete" (click)="chargeSubmit()">{{'Pay'}}</button>
    </div>

</div>