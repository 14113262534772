import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  
  constructor() { }
  uploadurl = `${environment.bucketurl}`;
  // FOLDER:any;/
  Bucketname = `${environment.bucketname}`;
  bucket = new S3(
    {
      accessKeyId: 'AKIAZIVAFNGO4NA5LYXG',
      secretAccessKey: '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',
      region: 'us-east-2'
    }
  );

  async uploadFile(fileToUpload: File) {
    try {
      const params = {
        Bucket: this.Bucketname,
        Key: fileToUpload.name,
        Body: fileToUpload,
        ACL: 'public-read'
      };
      
      return new Promise((resolve, reject) => {
        this.bucket.upload(params, function (err: any, data: any) {
          if (err) {
                 alert()
            reject(err);
            return false;
          } else {
            resolve(data?.Location);
            return data?.Location;
          }
        });
      });
    } catch (err:any) {
      // console.log("an error was occured during image uploading")
      console.error(err.message);
    }
    finally {
    }
  }
  
}
