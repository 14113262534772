import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideComponent } from './aside/aside.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SecureComponent } from './secure/secure.component';
import { PublicComponent } from './public/public.component';
import { RouterModule } from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';
import { PartialsModule } from '../partials/partials.module';

// import { MatIcon, MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    AsideComponent,
    HeaderComponent,
    FooterComponent,
    SecureComponent,
    PublicComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    PartialsModule
    // MatIconModule
  ],
  exports:[
    AsideComponent, HeaderComponent, FooterComponent,PublicComponent,SecureComponent
  ]
})
export class ThemeModule { }
