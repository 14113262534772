import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { AppConst, APP_CONSTANT } from 'src/app/core/_constants/app.constant';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { MainService } from 'src/app/core/_services/main.service';
import { ValidationService } from 'src/app/core/_services/validation.service';
import { OnlinePaymentsComponent } from '../online-payments/online-payments.component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})

export class PaymentsComponent implements OnInit {
  validationErrorMessage= APP_CONSTANT.VALIDATION;
  maxLength = AppConst.MAXLENGTH;
  public chargeForm: FormGroup;
  submitted: boolean = false;
  methodArray: any = [
    {key: 'In Person', value: 'In Person'},
    {key: 'Phone', value: 'Phone'}
  ]
  balanceRemaining: any = 0.00;

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<PaymentsComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private service: MainService, private fb: FormBuilder,private loader: NgxUiLoaderService, private toastr: ToastrService,private router: Router,public validationService: ValidationService) { 
  }


  ngOnInit(): void {
    this.chargeForm = this.createForm();
    this.balanceRemaining = (Number(this.data?.oppDetails?.totalAmount) - Number(this.data?.oppDetails?.totalAmountPaid)).toFixed(2)
  }
  
  createForm(){
    return this.fb.group({
      notes: [''],
      paymentType: ['partial', Validators.required],
      amount: ['',Validators.required],
      method: ['',Validators.required]
    })
  }

  get f() { return this.chargeForm.controls; }

  close(value:Boolean){
    if(this.chargeForm.valid){
      this.dialogRef.close(value);
    }
  }

  chargeSubmit() {
    this.submitted = true;
    if (this.chargeForm.valid) {
      const dialogRef = this.dialog.open(OnlinePaymentsComponent, {
        maxHeight: '100vh',
        width: '700px',
        maxWidth: '100%',
        // panelClass: 'custom-modalbox',
        disableClose: true,
        data: {
          opportunityId: this.data.oppDetails._id,
          details: this.data.oppDetails,
          method: this.chargeForm.value.method,
          notes: this.chargeForm.value.notes,
          total: this.chargeForm.value.amount,
          paymentType: 'online',
        }
      });
      dialogRef.afterClosed().subscribe((confirm: any) => {
        if (confirm !== undefined && confirm !== null && confirm) {
          this.dialogRef.close(true);
        }
      });
    }
  }

  setAmount(event){
    if(event.value=='complete'){
        this.chargeForm.patchValue({
          amount: this.balanceRemaining
        })
    }else{
      this.chargeForm.patchValue({
        amount: ''
      })
    }
  }

  setTodecimal(): any {
    if(this.chargeForm.value.amount){
      this.chargeForm.patchValue({amount: Number(this.chargeForm.value.amount).toFixed(2)})
    }
  }

  fixedTotal(){
   if(this.data?.oppDetails && this.data?.oppDetails?.totalAmount){
    return '$' + this.balanceRemaining;
   }
   return '$0.00'
  }

}
