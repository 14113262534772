import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { AuthService } from 'src/app/core/_services/auth.service';
import { CommonService } from 'src/app/core/_services/common.service';
import { LocalStorageProvider } from 'src/app/core/_services/storage.service';
;

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  navigationList: any = [
    {
      Name: "Dashboard",
      NgRoutes: `${RouteConstant.Dashboard}`,
      BlackIcon: `../../../../assets/images/dashboard.png`,
      WhiteIcon: `../../../../assets/images/whitedashboard.png`
    },
    {
      Name: "Messages",
      NgRoutes: `${RouteConstant.Messages}`,
      BlackIcon: `../../../../assets/images/question_answer.svg`,
      WhiteIcon:`../../../../assets/images/whitequestion.svg`

    }
  ];
  profile :any;
  selectedNav:any = '';
  isShow=false
  clickEventsubscription: Subscription;
  constructor(private storageService:LocalStorageProvider,private route:Router,private dialog: MatDialog,private authService:AuthService,public commonService: CommonService) { 
  }
  
  ngOnInit(): void {
    this.commonService.currentUser$.subscribe(res => {
     if(res){
       this.profile = this.storageService.getItem('CurrentUser');
     }
    })
    this.profile = this.storageService.getItem('CurrentUser');
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isShow = false;  
      }
    });
  }
  viewProfile(){
    this.route.navigate([`${RouteConstant.Profile}`]);
  }
  @HostListener('window:click', ['$event'])
  @HostListener('window:scroll', ['$event'])

  onEvent(event: any) {
    if(event.target.id == 'profile-image' && this.isShow==false ){
      this.isShow = true;
    }else {
      this.isShow = false;
    }
  }

  getInitials(name: string) {
    var names = name.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();
  
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
}


