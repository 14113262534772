<!--header section-->
<section>
    <div class="container-fluid breadcrumb_comp">
        <div class="d-flex justify-content-between align-items-center">
            <div class="select-service-provider">
                <ng-content></ng-content>
                <!-- <div class="custom-breadcrumb border-none">
                                        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
                                            <ol class="breadcrumb">
                                            <ng-content></ng-content>
                                            </ol>
                                        </nav>
                                    </div> -->
            </div>
            <div class=" ">
                <div class="custom-breadcrumb breadcrumb_none">
                    <nav aria-label="breadcrumb ">
                        <ol class="breadcrumb justify-content-between align-items-center m-0">
                            <div class="">
                               <span class="notification px-2">
                                    <img  src="/assets/images/svg/notify.svg" height="16px" width="12px" routerLink="notification" />
                               </span>
                                <span class="me-2 display-profile-name">{{profile?.customerName}}</span>
                                <span class="profile-image">
                                    <button id="profile-image" class="rounded-circle imageCustomer" type="button" data- bs-toggle="dropdown"
                                    aria-expanded="false">
                                        {{getInitials(profile?.customerName)}}
                                    </button>
                                    <!-- <img id="profile-image" class="rounded-circle" type="button" data- bs-toggle="dropdown"
                                    aria-expanded="false"  [src]="profile?.profilePic || 'assets/images/svg/person.svg'"/> -->
                                </span>
                               
                            </div>
                        </ol>
                    </nav>
                    <div class="dropdown ">
                        <ul class="dropdown-menu border-0" [ngClass]="isShow ? 'show':'hide'">
                            <li><a class="dropdown-item" (click)="viewProfile()">
                                    <img src="/assets/images/user-3-fill.svg">
                                    Profile</a></li>
                            <hr class="my-1">
                            <li><a class="dropdown-item" (click)="commonService.openLogoutDialog()">
                                    <img src="/assets/images/logout-box-r-line.svg">
                                    Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<hr />
<!--header section-->