import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/core/_services/common.service';
import { NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {NgxGalleryImage} from '@kolkov/ngx-gallery';
import {NgxGalleryAnimation} from '@kolkov/ngx-gallery';
import { MainService } from 'src/app/core/_services/main.service';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
@Component({
  selector: 'app-review-detail-carousel',
  templateUrl: './review-detail-carousel.component.html',
  styleUrls: ['./review-detail-carousel.component.scss']
})
export class ReviewDetailCarouselComponent implements OnInit {
  @Input() activeEstimate: any
  @Input() allEstimates:any
  @Output() priceShow = new EventEmitter<boolean>(); 
  index:number = 0;
  hidePrice: boolean = true;
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  
  constructor(private commonService: CommonService,private service: MainService, 
    ) { }

  ngOnInit(): void {

    this.getActiveEstimate();
       this.priceShow.emit(true);

    this.galleryOptions = [
      {
        width: '100%',
        height: '400px',
        preview:true,
        closeIcon:'close',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewCloseOnEsc:true,
        previewCloseOnClick:true,
        imageArrows:true,
        previewKeyboardNavigation:true,
        imageArrowsAutoHide:true,
        thumbnailsArrows:true,
        previewZoom:true,
        thumbnailsRemainingCount:false,
        previewArrows:true,
        arrowPrevIcon: 'prev-arrow',
        arrowNextIcon: 'next-arrow',
        
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '400px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin:10,
        thumbnailsColumns: 3,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: true,
        thumbnailsColumns: 2,
       
      }
    ];
    this.setGalleryItems();
  }
  
  setGalleryItems(){
    this.galleryImages =  this.activeEstimate.itemImage.map((item:any) => ({ small: item.link, medium: item.link, big: item.link }))
  }

  setActiveEstimate(index:any){
    this.commonService.setActiveEstimate(this.allEstimates[index]);
    this.getActiveEstimate();
  }
  getActiveEstimate(){
    this.commonService.activeEstimate$.subscribe(async activeEstimate => {
      if(activeEstimate){
        this.activeEstimate =  await activeEstimate;
        this.index = this.setIndex(activeEstimate?._id);
        this.getDefaultStatus(activeEstimate?.inspectionId);
       // this.setImage(this.activeEstimate?.itemImage[0],this.index);
        this.setGalleryItems();
      }
    });
  }
  activeEstimateStatus:any
  getDefaultStatus(id:any){
    this.service.get({},`${API_ROUTES.OPPORTUNITY.getInspectionBtnStatus}`).pipe().subscribe(response => {
      if (response.status) {
        let initialData = response.data?.list;
        let foundEstimate = initialData.find((x) => x?._id == id);    
        if (foundEstimate) {
          this.activeEstimateStatus = foundEstimate.fieldName;
        } else {
          this.activeEstimateStatus=''
          console.log('Estimate not found for id:', id);
        }
      }
      
    })
  }
  setIndex(id:any){
    return this.allEstimates.map((object:any) => object._id).indexOf(id);
  }


  previous(){
    this.index--;
    this.setActiveEstimate(this.index);
  }
  next(){
    this.index++;
    this.setActiveEstimate(this.index);
  }
  setStatus() {
    switch (this.activeEstimate.inspectionStatus) {
      case 'Pass':
        return 'pass-status';
      case 'Fail':
        return 'fail-status';
      default:
        return 'null-status';
    }
  }
  hidePriceDetails(e:any){
    this.priceShow.emit(e.checked);
    this.hidePrice =  !this.hidePrice;
  }
}
