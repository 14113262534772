
export class AppConst {
	// encryption key
	public static readonly ENC_KEY = "!ServiceGalaxy";

	// API KEY
	public static readonly API_KEY = "1234";

	// numberValidation
	public static readonly NUMBER_WITH_TWO_DECIMAL =
		/^\s*(?=.*[1-9])\d*(?:(\.|\,)\d{1,2})?\s*$/;
	public static readonly NUMBER_WITH_ZERO_AND_TWO_DECIMAL =
		/^\s*(?=.*[0-9])\d*(?:(\.|\,)\d{1,2})?\s*$/;
		//emailValidation
	public static readonly EMAIL_PATTERN = 
		`^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$`;

	public static readonly DATE_FORMAT = `dd MMM YYYY`;
	public static readonly TIME_FORMAT = `shortTime`;
	public static readonly PASSWORD_PATTERN = 
		`(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z0-9\d@$!%*?&].{7,19}`;
	public static readonly MOB_EMAIL_PATTERN = /(^[_a-z0-9]+(.[_a-z0-9]+)@[a-z0-9-]+(.[a-z0-9-]+)(.[a-z]{2,4})$)|(^\d{10}$)/;
	public static readonly PHONE_PATTERN = /^\d{10}$/;

	public static readonly COUNTRY_CODE='+91';

	// API BASE URL
	// public static readonly API_BASE_URL = environment.API_BASE_URL;

	public static readonly APPLICATION_BASE_URL = window.location.origin;

	public static readonly ZIPCODE_PATTERN = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

	
	

	// server HTTP header status code
	public static readonly HTTP_STATUS: HttpStatus = {
		OK: 200,
		SERVER_INTERNAL_ERROR: 500,
		FORBIDDEN: 401,
		IFNOTOK: 201,
		BAD_REQUEST: 400,
		NOT_FOUND: 404,
		NO_CONTENT: 204,
	};
	public static readonly SERVER_CODES = {
		VALIDATION_ERROR: "E103",
	};
	public static readonly ERROR_MESSAGES = {
		IMAGE_DIMENSIONS:
			"Image should have min dimensions {{width}}X{{height}}",
		SERVER_ERROR: "Something went wrong",
	};
	public static STORAGE_KEYS={
		USER_TOKEN:'USER_TOKEN',
		USER_ID:'USER_ID',
		USER_DETAILS:'USER_DETAILS',
		USER_TYPE:'userType'
	};
	public static MAXLENGTH = {
		phoneNumber: 10,
		pinCode: 10,
		name: 35,
	}

}
export interface HttpStatus {
    OK: number;
    IFNOTOK: number;
    NOT_FOUND: number;
    FORBIDDEN: number;
    BAD_REQUEST: number;
    SERVER_INTERNAL_ERROR: number;
    NO_CONTENT: number;
}
export const APP_CONSTANT = {
	DASHBOARD: {
		dashboard: 'Dashboard',
       	client : 'Total Client',
	  	customer:'Total Customers',
	   	employees:'Total Employees',
	   	client_registration:'Client Registration Monthly'
    },
	VALIDATION: {
		name: 'Name is required',
		email: 'Email id is required',
		validEmail: 'Please enter a valid email address',
		password: 'Please type your password',
		services: 'Services is required',
		companyName:'Company Name is required',
		clientName:'Client Name is required',
		phoneNumber:'Phone Number is required',
		address:'Address is required',
		city:'City is required',
		state:'State is required',
		country:'Country is required',
		zipCode: 'Zipcode is required',
		profile: 'Profile image is required',
		newPassword: 'New Password is required',
		oldPassword: 'Current Password is required',
		confirmPassword: 'Confirm Password is required',
		validNewPassword: 'Enter a valid password',
		validZipCode: 'Enter a valid Zipcode',
		imageExt: 'Please upload a png image',
		serviceAdd: 'Service Address is required',
		businessAdd: 'Business Address is required',
		billingAdd: 'Billing address is required please select either service address as a billing address or business address',
		locationName: 'Location name is required',
		locationEmail: 'Location email is required',
		locationContact: 'Location contact is required',
		locationPhone: 'Location phone number is required',
		validPhoneEmail: 'Enter a valid email or mobile number',
		phoneEmail: 'Email or mobile number',
		signature: 'Signature is required'
	},
	LOGOUT:{
		logout:'Logout',
		title:'Are you sure you want to logout?',
		cancel:'Cancel'
	},
	VERIFYEMAIL: {
		success:{
			heading : 'Verification Successful',
			subHeading: 'Your email address has been verified.',
			image : 'assets/images/check.svg',
			button: 'Back to Login'
		},
		failed: {
			heading : 'Verification Failed',
			subHeading: 'Email address verification failed. This link is invalid.',
			image : 'assets/images/close-circle-fill.png',
			button: 'Back to Login'
		},
		session: {
			heading : 'Session Expired',
			subHeading: '',
			image : '../../../../../assets/images/close-circle-fill.png',
			button: 'Back to Login'
		},
		verified: {
			heading : 'Account already verified',
			subHeading: '',
			image : 'assets/images/check.svg',
			button: 'Back to Login'
		}
	},
}
	
 
