<div class="modal-header p-0">
    <button class="button-close base-btn mb-2" mat-button [mat-dialog-close]="false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
        </svg>
    </button>
</div>
<div class="modal-body p-0">
    <img [src]="data?.img">
    <p class="pt-2"> Collect Signature</p>
   <form>
       <div class="form-group text-start ">
           <label class="mb-3">Draw Here</label><br> 
           <ngx-signature-pad #signaturePad class="display-sign-pad"
          (signature)="getSignature($event)"
          [containerStyle]="'margin:0px auto;cursor:pointer;display:block;border-radius:9px;border: 1px solid #E8E8E8;max-width:550px;min-width:240px;height:240px'"
          [height]="240"
          [width]="550"
          [lineStroke]="3" 
          [lineColor]="'#IDEIDE'"></ngx-signature-pad>
          <ngx-signature-pad #signaturePad2 class="d-none"
          (signature)="getSignature($event)"
          [containerStyle]="'margin:0px auto;cursor:pointer;display:block;border-radius:9px;border: 1px solid #E8E8E8;'"
          [height]="100"
          [width]="240"
          [lineStroke]="3" 
          [lineColor]="'#IDEIDE'"></ngx-signature-pad>
      <!-- <div class="d-flex justify-content-between">
          <button mat-raised-button class="mx-2" color="primary"
              (click)="clear(signaturePad)">Clear</button>
          <button mat-raised-button class="mx-2" color="primary"
              (click)="download(signaturePad)">Download</button>
          <button mat-raised-button class="mx-2" color="primary"
              (click)="getFile(signaturePad)">Get file</button>
      </div> -->
            <!-- <div class="image-upload" id="imagePreview" width="100%" [style.backgroundImage]="'url('+imageUrl+')'">
                <input type="file" id="imageUpload" (change)="fileupload($event)" title="Select signature" required>
            </div> -->
            <!-- <div *ngIf="imageInvalid" class="invalid-feedback">{{imageInvalidMsg}}</div> -->
            <!-- <div *ngIf="(imageUrl==='' || imageUrl === undefined) && !imageInvalid" class="invalid-feedback">{{imageInvalidMsg}}</div> -->
       </div>
   </form>
</div>
<div class="modal-footer pt-3">
    <button type="button" class="btn base-btn cancel" [mat-dialog-close]="false">Cancel</button>
    <button type="button" class="btn base-btn reset me-4 display-sign-pad"  (click)="clear(signaturePad)">Reset</button>
    <button type="button" class="btn base-btn reset me-4 d-none"  (click)="clear(signaturePad2)">Reset</button>
    <button type="submit" [disabled]='isSignatureNotAdded' class="btn base-btn submit display-sign-pad" (click)="getFile(signaturePad)">Confirm Approval</button>
    <button type="submit" [disabled]='isSignatureNotAdded' class="btn base-btn submit d-none" (click)="getFile(signaturePad2)">Confirm Approval</button>

</div>
