import { environment } from "src/environments/environment";

export const API_ROUTES = {
    ACCOUNT: {
        login: `${environment.apiBaseUrl}v1/customer/login`,
        updateTC: `${environment.apiBaseUrl}v1/customer/update-tc-status`,
        viewProfile: `${environment.apiBaseUrl}v1/customer/viewProfile`,
        updateProfile: `${environment.apiBaseUrl}v1/customer/updateProfile`,
        changePassword: `${environment.apiBaseUrl}v1/customer/changePassword`,
        verifyOtp: `${environment.apiBaseUrl}v1/customer/otpVerify`,
        resendOtp: `${environment.apiBaseUrl}v1/customer/resendOTP`,
        serviceProviderList:`${environment.apiBaseUrl}v1/customer/serviceProviders`,
        changeProvider: `${environment.apiBaseUrl}v1/customer/changeServiceProvider`,
        verifyMail: `${environment.apiBaseUrl}v1/customer/emailVerify`,
        hideShowDetails: `${environment.apiBaseUrl}v1/customer/getHideDetails`,
        notification: `${environment.apiBaseUrl}v1/customer/notifications`,
        events: `${environment.apiBaseUrl}v1/customer/myEvents`,
        myTask: `${environment.apiBaseUrl}v1/customer/myTask`,
        pushNotification:`${environment.apiBaseUrl}v1/customer/pushNotification`
    },
    DASHBOARD:{
        getTasks:`${environment.apiBaseUrl}v1/customer/myTask`,
    },
    OPPORTUNITY: {
        getOpportunityList: `${environment.apiBaseUrl}v1/opportunity/opportunities`,
        getOpportunityDetails: `${environment.apiBaseUrl}v1/opportunity/opportunityDetail`,
        approveEstimate: `${environment.apiBaseUrl}v1/opportunity/approveEstimates`,
        editOpp: `${environment.apiBaseUrl}v1/opportunity/editOpportunity`,
        addPayment: `${environment.apiBaseUrl}v1/opportunity/addPayments`,
        previewWorkOrder : `${environment.apiBaseUrl}v1/opportunity/previewWorkOrder`,
        getInspectionBtnStatus:`${environment.apiBaseUrl}v1/customer/getinspection`
    },
    JOB: {
        getJobList: `${environment.apiBaseUrl}v1/opportunity/jobs`,
        getJobDetails: `${environment.apiBaseUrl}v1/opportunity/JobDetail`
    },
    INVOICE:{
        getInvoiceList:`${environment.apiBaseUrl}v1/opportunity/invoices`
    },
    PAYMENT:{
        getPaymentList:`${environment.apiBaseUrl}v1/opportunity/payments`,
        checkout: `${environment.apiBaseUrl}v1/opportunity/checkout`
    }
}