import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-breadcrumb',
  templateUrl: './sub-breadcrumb.component.html',
  styleUrls: ['./sub-breadcrumb.component.scss']
})
export class SubBreadcrumbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
