<div class="opportunity pt-0">
    <div class="d-flex justify-content-between align-items-center">
        <h3 class="heading mb-0 mt-2">Notes</h3>
    </div>
    <div class="opportunity-list">
        <div *ngIf="noteList && noteList.length; else noDataFound" class="table-responsive scroll-hide">
            <table class="table ">
                <thead class="p-2">
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">By Employee</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let note of noteList">
                        <td >{{note?.updatedAt |date : 'MMM dd, yyyy' || '---'}}</td>
                        <td>{{note?.createdByName || '---'}}</td>
                        <td class="description">
                            <div>{{note?.description || '---'}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #noDataFound>
            <div class="no-data">No Note Found.</div>
        </ng-template>
    </div>
</div>