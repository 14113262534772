import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APP_CONSTANT } from 'src/app/core/_constants/app.constant';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { CommonService } from 'src/app/core/_services/common.service';
import { LocalStorageProvider } from 'src/app/core/_services/storage.service';
import { ConfirmDialogComponent } from '../../partials/dialogs/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {
  profile = this.storageService.getItem('CurrentUser');
  isShow=false
  navigationList: any = [
    {
      Name: "Dashboard",
      NgRoutes: `${RouteConstant.Dashboard}`,
      BlackIcon: `../../../../assets/images/dashboard.png`,
      WhiteIcon: `../../../../assets/images/whitedashboard.png`
    },
    {
      Name: "Messages",
      NgRoutes: `${RouteConstant.Messages}`,
      BlackIcon: `../../../../assets/images/question_answer.svg`,
      WhiteIcon:`../../../../assets/images/whitequestion.svg`

    }
  ];
  totalMessgaes:any =0;
  value:any ;
  constructor(private storageService: LocalStorageProvider,
    private route:Router,private dialog: MatDialog,public commonService: CommonService) {}

   ngOnInit(): void {
    this.commonService.getMessage().subscribe((res:any)=>{
      console.log(res)
      this.value = res
      console.log(res)
      // this.totalMessgaes = Object.values(res)
      // debugger
      // this.totalMessgaes.forEach((ele)=>{
        
      //   this.value = this.value+ele;
        
      // })
      // console.log(this.value)

    })

  }

  showdropdown(){
    this.isShow=!this.isShow
  }
  
}
