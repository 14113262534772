<div id="review-work-order" class="d-flex align-items-center estimate-details mt-4">
    <div (click)="index > 0 && previous()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.2" height="20.437" viewBox="0 0 16.2 20.437" [ngClass]="index <= 0 ? 'svg-opacity':'cursor-pointer'">
            <path id="eject" d="M14.954,21.208,6.5,8.529A1.75,1.75,0,0,1,7.963,5.8h16.9a1.75,1.75,0,0,1,1.462,2.729L17.877,21.208A1.744,1.744,0,0,1,14.954,21.208Z" transform="translate(22 -6.197) rotate(90)" fill="#ffff"/>
        </svg>
    </div>

    <div class="opportunity pt-0 w-100 mx-2" [ngClass]="setStatus()">
        <div class="opportunity-list p-3">
            <div class="d-flex justify-content-between align-items-center">
                <div class="title" *ngIf="activeEstimate?.inspectionStatus"> 
                    Inspection Status
                </div>
                <!-- <div class="inspection-button d-flex align-items-center">
                    <img  *ngIf="activeEstimate?.inspectionStatus === 'Pass'" src="assets/images/svg/pass-check.svg"/>
                    <img  *ngIf="activeEstimate?.inspectionStatus === 'Fail'" src="assets/images/svg/fail-cross.svg"/>
                    <img  *ngIf="activeEstimate?.inspectionStatus === 'N/A'" src="assets/images/svg/spam.svg"/>
                    <span class="px-1">{{activeEstimate.inspectionStatus || '---'}}</span>
                </div> -->
                <div class="pass-insp-status d-flex align-items-center" *ngIf="activeEstimate?.inspectionStatus" > 
                   {{activeEstimate?.inspectionStatus}}
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mt-2">
                <div class="title">
                    <!-- <svg id="money-dollar-circle-fill_3_" data-name="money-dollar-circle-fill (3)" xmlns="http://www.w3.org/2000/svg" width="22.45" height="22.45" viewBox="0 0 22.45 22.45">
                        <path id="Path_69432" data-name="Path 69432" d="M0,0H22.45V22.45H0Z" fill="none"/>
                        <path id="Path_69433" data-name="Path 69433" d="M11.354,20.708a9.354,9.354,0,1,1,9.354-9.354A9.354,9.354,0,0,1,11.354,20.708ZM8.08,13.225V15.1h2.339v1.871H12.29V15.1h.935a2.339,2.339,0,0,0,0-4.677H9.483a.468.468,0,0,1,0-.935h5.145V7.613H12.29V5.742H10.419V7.613H9.483a2.339,2.339,0,1,0,0,4.677h3.742a.468.468,0,0,1,0,.935Z" transform="translate(-0.129 -0.129)" fill="#IDEIDE"/>
                      </svg> -->
                </div>
               <div class="d-flex align-items-center">
                  <label class="switch me-1">
                    <input type="checkbox" [checked]="true" (change)="hidePriceDetails($event.target)">
                    <span class="slider round"></span>
                </label>
                <svg id="money-dollar-circle-fill_3_" data-name="money-dollar-circle-fill (3)" xmlns="http://www.w3.org/2000/svg" width="22.45" height="22.45" viewBox="0 0 22.45 22.45">
                    <path id="Path_69432" data-name="Path 69432" d="M0,0H22.45V22.45H0Z" fill="none"/>
                    <path id="Path_69433" data-name="Path 69433" d="M11.354,20.708a9.354,9.354,0,1,1,9.354-9.354A9.354,9.354,0,0,1,11.354,20.708ZM8.08,13.225V15.1h2.339v1.871H12.29V15.1h.935a2.339,2.339,0,0,0,0-4.677H9.483a.468.468,0,0,1,0-.935h5.145V7.613H12.29V5.742H10.419V7.613H9.483a2.339,2.339,0,1,0,0,4.677h3.742a.468.468,0,0,1,0,.935Z" transform="translate(-0.129 -0.129)" fill="#264a9e"/>
                  </svg>
               </div>
            </div>
            <form class="">
                <div class="form-group">
                    <label class="mb-2">Live Media</label>
                    <div class="row">
                        <div *ngIf="galleryImages.length else noMedia" class="col-md-12">
                             <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
                        </div>
                        <ng-template #noMedia>
                            <div class="no-data">No Media Found.</div>
                        </ng-template>
                    </div>
                </div>
            </form>
           
            <form>
                <div class="row d-flex align-items-center flex-wrap">
                    <div class="form-group col-md-2">
                        <label class="ps-2">Item No.</label>
                        <input type="text" class="form-control mt-1 p-2" readonly
                            [value]="index+1" placeholder="Item Number" />
                    </div>
                    <div class="form-group col-md-2">
                        <label class="ps-2">SKU</label>
                        <input type="text" class="form-control mt-1 p-2" readonly 
                            [value]="activeEstimate?.sku" placeholder="SKU" />
                    </div>
                    <div class="form-group col-md-2">
                        <label class="ps-2">Item Name</label>
                        <input type="text" class="form-control mt-1 p-2" readonly 
                            [value]="activeEstimate?.itemName" placeholder="Item Name" />
                    </div>
                    <div *ngIf="hidePrice" class="form-group col-md-2">
                        <label class="ps-2">Qty</label>
                        <input type="text" class="form-control mt-1 p-2" readonly
                            [value]="activeEstimate?.quantity" placeholder="Quantity" />
                    </div>
                    <div *ngIf="hidePrice" class="form-group col-md-2">
                        <label class="ps-2">Price</label>
                        <input type="text" class="form-control mt-1 p-2" readonly
                           [value]="'$'+(activeEstimate?.price)" placeholder="Price" />
                    </div>
                    <div *ngIf="hidePrice" class="form-group col-md-2">
                        <label class="ps-2">Total</label>
                        <input type="text" class="form-control mt-1 p-2" readonly
                            [value]="'$'+activeEstimate?.total" placeholder="Total" />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label class="ps-2">Description</label>
                        <textarea type="text" class="form-control mt-1 p-2" readonly 
                            [value]="activeEstimate?.description" placeholder="Description"></textarea>
                    </div>
                    <!-- <div class="form-group col-md-6">
                        <label class="ps-2">Script</label>
                        <textarea type="text" class="form-control mt-1 p-2" readonly
                            [value]="activeEstimate?.script || '' "
                            placeholder="Script"></textarea>
                    </div> -->
                </div>
            </form>
        </div>
    </div>
    <div (click)="index < allEstimates.length-1 && next()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.2" height="20.437" viewBox="0 0 16.2 20.437" [ngClass]="index === allEstimates.length-1 ? 'svg-opacity':'cursor-pointer'">
            <path id="eject" d="M14.954,21.208,6.5,8.529A1.75,1.75,0,0,1,7.963,5.8h16.9a1.75,1.75,0,0,1,1.462,2.729L17.877,21.208A1.744,1.744,0,0,1,14.954,21.208Z" transform="translate(-5.8 26.634) rotate(-90)" fill="#ffff"/>
          </svg>
    </div>
</div>
