    <div class="galaxy m-0  d-flex " >
     <div class="sidebar p-0" >
        <app-aside></app-aside>
     </div>
     <div class=" p-0 dashboard_comp banner-img hv-100">
      <img class="bottomLogo" src="assets/images/white-logo.svg" alt="">
        <app-breadcrumb>
           <div  id="service-provider"  class="d-flex justify-content-between align-items-center" (click)="openModal()">
            <div class="display-provider">
               <img [src]="serviceProviderDetail?.logo ? serviceProviderDetail?.logo  :'assets/images/person.svg'" class="rounded-circle provider-image"/>
               <span  class="ps-2 provider-name">{{serviceProviderDetail?.name}}</span>
             </div>
             <div class="ps-5 mb-1">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <g id="Group_14213" data-name="Group 14213" transform="translate(0 -0.001)">
                    <circle id="Ellipse_23" data-name="Ellipse 23" cx="3" cy="3" r="3" transform="translate(0 0.001)" fill="#264a9e"/>
                    <circle id="Ellipse_26" data-name="Ellipse 26" cx="3" cy="3" r="3" transform="translate(0 10.001)" fill="#264a9e"/>
                    <circle id="Ellipse_24" data-name="Ellipse 24" cx="3" cy="3" r="3" transform="translate(10 0.001)" fill="#264a9e"/>
                    <circle id="Ellipse_25" data-name="Ellipse 25" cx="3" cy="3" r="3" transform="translate(10 10.001)" fill="#264a9e"/>
                  </g>
                </svg>
             </div>
           </div>
         
           <!-- <li class="breadcrumb-item">{{commonService?.title?.value}}</li> -->
        </app-breadcrumb>
        <p>
            <router-outlet></router-outlet>
        </p>
     </div>
    </div>

