import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { API_ROUTES } from '../_constants/api-route.constant';
import { LocalStorageProvider } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  constructor(
      private Http:HttpClient,
      private storageService: LocalStorageProvider,
      private router: Router,
      private loader: NgxUiLoaderService,
      private toastr: ToastrService
    ) { }

  login(body:Object): Observable<any> {
    return this.Http.post<any>(`${API_ROUTES.ACCOUNT.login}`,body).pipe(
      catchError(err => {
        this.toastr.error(err?.error?.msg)
        // err.statusText? this.toastr.error(err.statusText): this.toastr.error(err.error.message);
        return err;
      }),
      map(resp => resp),
    );
  }
  get isLoggedIn() {
    let token = this.storageService.getItem('token');
    let cUser = this.storageService.getItem('CurrentUser')
    if (token != undefined && token != null && cUser != undefined && cUser != null) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.asObservable();
  }
  logout() {
    this.loader.start();
    this.storageService.clearAll();
    this.loggedIn.next(false);
    this.loader.stop();
    this.router.navigate(['']);
  }
}
