import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { APP_CONSTANT, AppConst } from 'src/app/core/_constants/app.constant';
import { MainService } from 'src/app/core/_services/main.service';
import { ValidationService } from 'src/app/core/_services/validation.service';

@Component({
  selector: 'app-cheque-payment',
  templateUrl: './cheque-payment.component.html',
  styleUrls: ['./cheque-payment.component.scss']
})
export class ChequePaymentComponent implements OnInit {
  validationErrorMessage= APP_CONSTANT.VALIDATION;
  maxLength = AppConst.MAXLENGTH;
  public chargeForm: FormGroup;
  submitted: boolean = false;

  constructor( public dialogRef: MatDialogRef<ChequePaymentComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private service: MainService, private fb: FormBuilder,private loader: NgxUiLoaderService, private toastr: ToastrService,private router: Router,public validationService: ValidationService) { 
        
  }


  ngOnInit(): void {
    this.chargeForm = this.createForm();
    console.log(this.data)
  }
  
  createForm(){
    return this.fb.group({
      payeeName: ['', Validators.required],
      date: ['', Validators.required],
      chequeNumber: ['', Validators.required],
      bankName: ['',Validators.required]
    })
  }

  get f() { return this.chargeForm.controls; }

  close(value:Boolean){
    if(this.chargeForm.valid){
      this.dialogRef.close(value);
    }
  }

  chargeSubmit(){
    this.submitted = true;
    if(this.chargeForm.valid){
      let data;
      if(this.data.isRefund){
         data = {
          ...this.data, 
          payeeDetailForCheque : {
            payeeName : this.chargeForm.value.payeeName,
            bankName : this.chargeForm.value.bankName,
            chequeNumber : this.chargeForm.value.chequeNumber,
            date: this.chargeForm.value.date
           },
           senderId : this.data?.details?.operDetails?.emp_data.length? this.data?.details?.operDetails?.emp_data[0]._id : '',
           receiverId : this.data?.details?.operDetails?.customerId,
          opportunityAmount:  this.data.details?.operDetails.totalAmount
        }
      }
      else{
         data = {
          ...this.data, 
          payeeDetailForCheque : {
            payeeName : this.chargeForm.value.payeeName,
            bankName : this.chargeForm.value.bankName,
            chequeNumber : this.chargeForm.value.chequeNumber,
            date: this.chargeForm.value.date
           },
          receiverId: this.data?.details?.operDetails?.emp_data.length? this.data?.details?.operDetails?.emp_data[0]._id : '',
          senderId: this.data?.details?.operDetails?.customerId,
          opportunityAmount:  this.data.details?.operDetails.totalAmount
        }
      }
      delete data['details']
      this.pay(data);
    }
  }

  pay(data){
    this.loader.start();
    this.service.post(data,`${API_ROUTES.OPPORTUNITY.addPayment}`).subscribe({
      next: (response)=>{
        if(response.status){
          this.loader.stop();
          this.toastr.success(response.msg);
          this.dialogRef.close(true)
        }
      },
      error: (err)=> {
        this.loader.stop();
        this.toastr.error(err.msg);
      },
      complete: ()=> this.loader.stop()
    })
  }


}
