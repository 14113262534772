<div class="opportunity pt-0">
    <div class="">
        <h3 class="heading mb-0 mt-2">Schedule</h3>
    </div>
    <div class="opportunity-list">
        <div *ngIf="schedules && schedules.length; else noDataFound" class="table-responsive scroll-hide">
            <table class="table ">
                <thead class="p-2">
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Event</th>
                        <th scope="col">Description</th>
                        <th scope="col">Assign to</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let schedule of schedules">
                        <td >{{schedule?.createdAt | date: 'dd MMM, yyyy'}}</td>
                        <!-- <td>{{schedule?.timeSlot}}</td> -->
                        <td>
                            {{ schedule.date | date:'hh:mm a'}} - {{getEndTime(schedule.date, schedule.duration)}}
                        </td>
                        <td >{{schedule?.event}}</td>
                        <td class="description">
                            <div>{{schedule?.description}}</div></td>
                        <td>{{schedule?.employeeName || ' ---'}}</td>
                        <td>
                            <span class="btn w-100" [ngClass]="getStatusClass(schedule?.status,schedule?.isReschedule)" >
                                {{ getStatusLabel(schedule?.status, schedule?.isReschedule) }}
                              </span>
                        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #noDataFound>
            <div class="no-data">No Schedule Found.</div>
        </ng-template>
    </div>
</div>
