<section id="sidebar" class="sidebar">
  <div class="logo">
    <img src="assets/images/logo_latest.png" />
  </div>
  <div class="pt-2">
    <div class="row row_div">
      <div class="position-sticky ">
        <div class="row ">
        <nav class="navbar navbar-expand-lg navbar-light bg-white">
            <div class="col-5 d-none">
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
            <div class="col-7 d-none">
              <img src="assets/images/logo_latest.png" />
            </div>
            <!-- <div class="custom-breadcrumb breadcrumb_none">
              <nav aria-label="breadcrumb ">
                <ol class="breadcrumb justify-content-between align-items-center mb-0">
                  <div class="display-profile">
                    <img class="rounded-circle" type="button" data- bs-toggle="dropdown" aria-expanded="false"
                      (click)="showdropdown()" [src]="profile?.profilePic || ''" />
                  </div>
                </ol>
              </nav>
              <div class="dropdown ">
                <ul class="dropdown-menu border-0" [ngClass]="isShow ? 'show':'hide'">
                  <li><a class="dropdown-item" [routerLink]="(['/profile'])" (click)="showdropdown()">
                      <img src="../../../../../assets/images/user-3-fill.svg" width="24px" height="24px">
                      Profile</a></li>
                  <hr class="my-1">
                  <li><a class="dropdown-item" (click)="showdropdown(); commonService.openLogoutDialog()">
                      <img src="../../../../../assets/images/logout-box-r-line.svg" width="24px" height="24px">
                      Logout</a></li>
                </ul>
              </div>
            </div> -->
          <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul class="nav flex-column w-100">
              <li class="nav-item" *ngFor="let navigation of navigationList">
                <a class="nav-link d-flex align-items-center mb-0" [routerLink]="navigation.NgRoutes"
                  [routerLinkActive]="['active']" aria-current="page">
                  <img [src]="navigation?.BlackIcon" width="20px" class="image_black" />
                  <img [src]="navigation?.WhiteIcon" width="20px" class="image_white">
                  <span data-feather="navigation?.NgRoutes" class="ms-2"></span>
                  <p class="side-menu-text">{{navigation?.Name}}</p>
                  <span class="notification mx-5" *ngIf="navigation?.Name=='Messages'" [ngStyle]="{'opacity': value>0 ? 1 : 0 }">
                    <span class="notification-count">
                        {{value > 9 ? '9+' :value }}
                    </span>
                </span>
                  <!-- <img src="../../../../assets/images/eject.svg" (click)="showNavigationMenu()" width="11px" class="image_black ms-5"/> -->
                  <!-- <img src="../../../../assets/images/eject (1).svg"width="11px" (click)="showNavigationMenu()" class="image_white ms-5">  -->
                </a>
              </li>
              <!-- <div  class="sidebar-dropdown">
                <ul class="nav flex-column w-100 border-0"  *ngIf="menuVisible">
                  <li class="nav-item" *ngFor="let navigation of navigationList" >
                    <a class="nav-link d-flex align-items-center" [ngClass]="selectedNav==navigation.Name ? 'active' : ''" aria-current="page" [routerLink]="'/'+ navigation.NgRoutes" routerLinkActive="active" (click)="setActive(navigation?.Name)">
                     <img >
                     <img src="../../../../assets/images/Rectangle 5429.svg" class="image_black"/>
                     <img src="../../../../assets/images/dot.svg" class="image_white">
                      <span data-feather="navigation?.NgRoutes" class="ms-2" ></span>
                      {{navigation?.Name}}
                    </a>
                  </li>
                </ul>
              </div> -->
              <!-- <li class="nav-item" >
                <a class="nav-link d-flex align-items-center mb-0" routerLink="messages" routerLinkActive="active" aria-current="page" >
                  <img src="../../../../assets/images/question_answer.svg" width="20px"  class="image_black"/>
                   <img src="../../../../assets/images/whitequestion.svg" width="20px" class="image_white"> 
                  <span data-feather="navigation?.NgRoutes" class="ms-2"></span>
                  <p class="side-menu-text">Messages</p>
                </a>
              </li> -->
            </ul>
          </div>
        </nav>
      </div>

      </div>
    </div>
  </div>
</section>