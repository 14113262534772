<div class="modal-header p-0">
    <button class="button-close base-btn mb-2" mat-button (click)="cancel(serviceProviderForm.value)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
        </svg>
    </button>
</div>
<div class="modal-body pt-0 pb-0">
    <p class="pt-0 heading"> {{data?.title }}</p>
    <app-filter-data (filterSelection)="searchServiceProvider($event)"></app-filter-data>
    <form [formGroup]="serviceProviderForm">
        <div *ngIf="totalCount === 0; else showData;" class="no-data">No Service Provider Found.</div>
        <ng-template #showData>
            <div class="providers-list custom-control custom-radio d-flex justify-content-between align-items-center mb-2" *ngFor="let provider of serviceProviders;let i=index;" (click)="checkButton(provider.companyId)">
                <div class="d-flex align-items-center">
                    <img [src]="provider?.logo ? provider?.logo:'/assets/images/person.svg'" height="30px"/>
                    <label class="custom-control-label " [for]="'provider'+i">{{provider?.name}}</label>
                </div>
                <div>
                    <input type="radio" [id]="'provider'+i" formControlName="serviceProvider" [value]="provider.companyId" class="custom-control-input">
                </div>
            </div>
        </ng-template>
    </form>
</div>

<div *ngIf="totalCount > 0" class="modal-footer m-0 p-0">
    <button type="button" class="btn base-btn cancel" (click)="cancel(serviceProviderForm.value)">{{data?.sayNo}}</button>
    <button type="submit" class="btn base-btn submit" (click)="submit(serviceProviderForm.value)">{{data?.sayYes}}</button>
</div>