import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-note-table',
  templateUrl: './note-table.component.html',
  styleUrls: ['./note-table.component.scss']
})
export class NoteTableComponent implements OnInit {
  @Input() noteList: any = [];
  constructor() { }

  ngOnInit(): void {
  }

}
