import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/_services/common.service';


@Component({
  selector: 'app-filter-data',
  templateUrl: './filter-data.component.html',
  styleUrls: ['./filter-data.component.scss']
})
export class FilterDataComponent implements OnInit {
  // @Input() showSearch: boolean = true;
  @Output() filterSelection = new EventEmitter<any>();
  searchField: FormControl;
  searchText: String= '';
  isSearch = false;
  clickEventsubscription: Subscription;
  constructor(private commonService: CommonService) { 
    this.searchField = new FormControl();
    this.clickEventsubscription = this.commonService.getResetClickEvent().subscribe(()=>{
      this.reset();
      })
  }

  ngOnInit(): void {
    this.search()
  }

  search(){
    this.searchField.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(res => {
      this.searchText = res;
      this.filterSelection.emit(this.searchText);
    });
  }

   /**resetSearch */
   reset() {
     this.filterSelection.emit(undefined)
  }
}
