<div class="opportunity pt-0">
    <div class="d-flex justify-content-between align-items-center">
        <h3 class="heading mb-0 mt-2">Files</h3>
    </div>
    <div class="opportunity-list">
        <div *ngIf="fileList && fileList.length; else noDataFound">
            <div class="imageContainer">
                <div class="imageDiv" *ngFor="let item of fileList; let i = index;">
                    <img [src]="item" alt="">
                </div>
            </div>
        </div>
        <ng-template #noDataFound>
            <div class="no-data">No file Found.</div>
        </ng-template>
    </div>
</div>