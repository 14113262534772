import { Routes} from '@angular/router';
import { RouteConstant } from 'src/app/core/_constants/route.constant';

export const SECURE_ROUTES: Routes = [
    {
        path: `${RouteConstant.Dashboard}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/dashboard/dashboard.module').then(mod => mod.DashboardModule)
    },
    {
        path: `${RouteConstant.Profile}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/profile/profile.module').then(mod => mod.ProfileModule)
    },
    {
        path: `${RouteConstant.Notification}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/notification/notification.module').then(mod => mod.NotificationModule)
    },
    {
        path: `${RouteConstant.Messages}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/messages/messages.module').then(mod => mod.MessagesModule)
    },
    {
        path: `${RouteConstant.Dashboard+'/'+RouteConstant.Opportunities}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/opportunities/opportunities.module').then(mod => mod.OpportunitiesModule)
    },
    {
        path: `${RouteConstant.Dashboard+'/'+RouteConstant.Jobs}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/jobs/jobs.module').then(mod => mod.JobsModule)
    },
    {
        path: `${RouteConstant.Dashboard+'/'+RouteConstant.Payments}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/payments/payments.module').then(mod => mod.PaymentsModule)
    },
    {
        path: `${RouteConstant.Dashboard+'/'+RouteConstant.Invoices}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/invoices/invoices.module').then(mod => mod.InvoicesModule)
    },
    {
        path: `${RouteConstant.Dashboard+'/'+RouteConstant.Events}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/myevent/myevent.module').then(mod => mod.MyeventModule)
    },
    {
        path: `${RouteConstant.Dashboard+'/'+RouteConstant.Tasks}`,
        data: { preload: true },
        loadChildren: () => import('../../pages/mytask/mytask.module').then(mod => mod.MytaskModule)
    },

];